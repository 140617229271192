(function() {
	var openPopup, closePopup, disableScrolling, enableScrolling, body = $('body'), btn = $('.toggle-dealers');

	openPopup = function(that) {
		$.ajax({
			type: 'Post',
			url:	that.data('url'),

			success: function(data){
				$('body').prepend(data);
			},

			complete: function() {
				disableScrolling();

				$('#popup').on('click', function(e) {
					e.stopPropagation();
					var that = $(this);

					closePopup($('#popup'));
				});

				$('#popup .l-popup__inner').on('click', function(e) {
					e.stopPropagation();
				});

				$('body').on('click', function(e){
					e.stopPropagation();

					closePopup($('#popup'));
				});

				$('#popup .c-popup-close').on('click', function(e) {
					e.stopPropagation();
					e.preventDefault();

					closePopup($('#popup'));
				})
			}
		});
	};

	closePopup = function(that) {
		that.remove();

		$('body').off();
		$('#popup').off();

		enableScrolling();
	};

	enableScrolling = function() {
		body.removeClass("no-scroll");
	};

	disableScrolling = function() {
		body.addClass("no-scroll");
	};

	btn.each(function() {
		$(this).on('click', function(e) {
			var that = $(this);

			e.preventDefault();

			openPopup(that);
		});
	});
})();