(function() {
	var btn 			= $('.c-reveal'),
			target 		= $(btn.data('target'));

	btn.on('click', function(e) {
		e.preventDefault();

		$(this).css('display', 'none');
		target.removeClass('visually-hidden-mobile');
	});
})();