(function() {
	var bp, positionMenu, unPositionMenu, openMenu;

	openMenu = $.opener({
		onOpen: function() {
			$('.l-header').addClass('l-header--open');
		},
		onClose: function() {
			setTimeout(function() {
				$('.l-header').removeClass('l-header--open');
			}, 300);
		}
	});

	positionMenu = function() {
		$('.l-header__inner').css('transform', 'translate3d(' + $(window).width() + 'px ,0,0)');
	};

	unPositionMenu = function() {
		$('.l-header__inner').css('transform', '');
	};

	$(window).on('breakpoint-change', function(e, breakpoint) {
		bp = breakpoint;

    if(breakpoint !== 'bp-medium' && breakpoint !== 'bp-small') {
    	openMenu.close();
    }
 	});

 	$(window).on('resize', function() {
 		if(typeof bp !== 'undefined' && (bp == 'bp-medium' || bp == 'bp-small')) {
 			positionMenu();
 		} else {
 			unPositionMenu();
 		}
 	});
})();