(function() {
	if($('#video')) {
		var btn = $('#video .c-video__inner'),
			videoContainer = $('#video .c-video__video-container');

		btn.on('click', function() {
			videoContainer.css('display', 'block');

			videoContainer.find('iframe')[0].src += '?autoplay=1';

			 // $(this).find('iframe')[0].src += "&autoplay=1";

			 // console.log($(this).find('iframe')[0]);

			$(this).css('display', 'none');
		});
	}
})();