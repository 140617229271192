(function($) {
	$.opener = function(options) {
		var opn = {
			options: $.extend({
				'btn': $('.opener__btn'),
				'alternativeCloseBtn': false, // Can be single element or array of elements
				'target': $('.opener__target'),
				'openClass': 'open',
				'openEvent': 'click',
				'closeEvent': 'click',
				'opened': false,
				'onOpen': false,
				'onClose': false
			}, options),

			init: function() {
				this.bindUI();
			},

			bindUI: function() {
				var that = this,
						options = this.options;

				options.btn.on(options.openEvent, function(e) {
					e.stopPropagation();

					var alt = options.alternativeCloseBtn;

					if (!options.opened) {
						that.open();

						if(alt !== false) {
							that.bindAltUI();
						}
					} else if (that.similarEvents()) {
						that.close();
					}
				});

				if(!this.similarEvents()) {
					options.btn.on(options.closeEvent, function(e) {
						e.stopPropagation();

						if(options.opened) that.close();
					});
				}
			},

			bindAltUI: function() {
				var i, a,
						alt = this.options.alternativeCloseBtn,
						closeEvent = this.options.closeEvent,
						that = this;

				if(alt.constructor === Array) {
					for(i = 0; i < alt.length; i++) {
						a = alt[i];

						a.on(closeEvent, function() {
							if(that.options.opened) that.close();
						});
					}
				} else {
					alt.on(closeEvent, function() {
						if(that.options.opened) that.close();
					});
				}
			},

			similarEvents: function() {
				return this.options.openEvent == this.options.closeEvent ? true : false;
			},

			open: function() {
				var options = this.options;

				options.target.addClass('open');
				options.opened = true;

				if(options.onOpen !== false) options.onOpen();
			},

			close: function() {
				var options = this.options;

				options.target.removeClass('open');
				options.opened = false;

				if(options.onClose !== false) options.onClose();
			}
		};

		opn.init();

		return {
			open: opn.open,
			close: opn.close,
			options: opn.options
		}
	}
})(jQuery);