(function() {
	var init, isLandscape, setFilter, filter, getTotalSlides, setTotalSlides, hasPortraits,
			slideshows 			= [],
			s 							= $('.slideshow'),
			landscapeSlide	= $('.c-slide--landscape'),
			portraitSlide		= $('.c-slide--portrait'),
			totalElement		= $('#c-pagination__total'),
			currentElement 	= $('#c-pagination__current');

	init = function() {
		s.each(function() {
			var slideshow = {
				container 			: $(this),
				landscapes 			: $(this).find('.c-slide--landscape, .c-slide--flex'),
				portraits 			: $(this).find('.c-slide--portrait').length > 0 ? $(this).find('.c-slide--portrait') : false,
				prevArrow 			: $(this).parent().find('.c-arrow--prev'),
				nextArrow 			: $(this).parent().find('.c-arrow--next'),
				pagination 			: $(this).parent().children('.c-pagination'),
				currentElement 	: $(this).parent().find('.c-pagination__current'),
				totalElement 		: $(this).parent().find('.c-pagination__total'),
				filter 					: null
			};

			slideshows.push(slideshow);
		});
	};

	isLandscape = function() {
		var wind = $(window);

		return wind.height() / wind.width() < 1.35 ? true : false;
	};

	hasPortraits = function(portraits) {
		return portraits === false ? false : true;
	}

	getTotalSlides = function(landscapes, portraits) {
		return isLandscape() || !hasPortraits(portraits) ? landscapes.length : portraits.length;
	};

	setTotalSlides = function(slideshow, landscapes, portraits) {
		slideshow.totalElement.html(getTotalSlides(landscapes, portraits));
	};

	setFilter = function(slideshow, container, landscapes, portraits) {

		if (isLandscape() && slideshow.filter !== 'landscape') {
			if(slideshow.filter !== null) container.slick('slickUnfilter');
			container.slick('slickFilter', landscapes);
			container.parent().addClass('l-slideshow--landscape');
			container.parent().removeClass('l-slideshow--portrait');

			setTotalSlides(slideshow, landscapes, portraits);
			slideshow.filter = 'landscape';
		}

		else if (!isLandscape() && slideshow.filter !== 'portrait') {
			if(slideshow.filter !== null) container.slick('slickUnfilter');

			container.slick('slickFilter', portraits);
			container.parent().addClass('l-slideshow--portrait');
			container.parent().removeClass('l-slideshow--landscape');

			setTotalSlides(slideshow, landscapes, portraits);
			slideshow.filter = 'portrait';
		}
	};

	$(document).ready(function() {
		var slideshow;

		init();

		for(var i = 0; i < slideshows.length; i++) {
			slideshow = slideshows[i];

			slideshow.container.slick({
				prevArrow: 			slideshow.prevArrow,
				nextArrow: 			slideshow.nextArrow
			});

			if (hasPortraits(slideshow.portraits)) {
				setFilter(slideshow, slideshow.container, slideshow.landscapes, slideshow.portraits);
			} else {
				slideshow.container.parent().addClass('l-slideshow--landscape');
			}

			setTotalSlides(slideshow, slideshow.landscapes, slideshow.portraits);

			slideshow.container.on('beforeChange', {slideshow: slideshow}, function(event, slick, currentSlide) {
				var s = event.data.slideshow;

				currentSlide = currentSlide + 2;
				currentSlide = currentSlide > getTotalSlides(s.landscapes, s.portraits) ? 1 : currentSlide;

				s.currentElement.html(currentSlide);
			});
		}
	});

	$(window).on('resize', function() {
		var slideshow;

		for(var i = 0; i < slideshows.length; i++) {
			slideshow = slideshows[i];

			if(hasPortraits(slideshow.portraits)) setFilter(slideshow, slideshow.container, slideshow.landscapes, slideshow.portraits);
		}
	});

	// s.on('beforeChange', function(slick, currentSlide) {
	// 	currentSlide = currentSlide.currentSlide + 2;
	// 	currentSlide = currentSlide > getTotalSlides() ? 1 : currentSlide;

	// 	currentElement.html(currentSlide);
	// });
})();