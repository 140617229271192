(function() {
	if($('#tiles').length !== 0) {
		var filter, hideTile, showTile, activate, inViewport, showFilters, hideFilters, hideHeadings,	showHeadings, mobile, reachedFooter, setScrolltop,
				menu = $('.l-header'),
				menuOpen 				= false,
				activeClass 		= "c-filter__link--active",
				filterContainer = $('#filter'),
				filterShown			= false,
				filters 				= $('#filter .c-filter__link'),
				tiles						= $('#tiles .c-tile').not('.c-tile--category'),
				tileHeadings		= $('#tiles .c-tile--category'),
				toggleMenu 			= $('#filter .c-filter__toggle'),
				footer 					= $('#footer');

		filter = function(category) {
			tiles.each(function() {
				if($(this).data('category') == category || category === false) {
					showTile($(this).parent());
				} else {
					hideTile($(this).parent());
				}
			})
		};

		hideTile = function(tile) {
			tile.css('display', 'none');
		};

		showTile = function(tile) {
			tile.css('display', 'block');
		};

		activate = function(button) {
			if(!button.hasClass(activeClass)) {
				filters.each(function() {
					$(this).removeClass(activeClass);
				});

				button.addClass(activeClass);

				return true;
			} else {
				button.removeClass(activeClass);

				return false;
			}
		};

		inViewport = function() {
			var scrollTop 		= $(window).scrollTop(),
					offset 				=	$('#tiles').offset().top,
					windowHeight 	= $(window).height();

			return scrollTop + windowHeight > offset ? true : false;
		};

		showFilters = function() {
			var filt = $('#filter');
			filt.addClass('c-filter--show');

			if (!mobile || menuOpen)  {
				filterContainer.css('transform', 'translate3d(0,' + menu.height() +'px,0)');
			} else {
				filterContainer.css('transform', 'translate3d(0,' + ((filt.height() * -1) + 36 + menu.height()) +'px,0)');
			}

			filterShown = true;
		};

		hideFilters = function() {
			var filterContainerHeight = filterContainer.height();

			filterContainer.css('transform', 'translate3d(0,' + (filterContainerHeight * -1) + 'px,0)');
			$('#filter').removeClass('c-filter--show');

			filterShown = false;
			menuOpen = false;
		};

		showHeadings = function() {
			tileHeadings.css('display', 'block');
		};

		hideHeadings = function() {
			tileHeadings.css('display', 'none');
		};

		setScrolltop = function() {
			var offset = $('#tiles').offset().top;

			$('html, body').animate({scrollTop: offset - 100}, '200');
		};

		// reachedFooter = function() {
		// 	var scrollTop 		= $(window).scrollTop(),
		// 			offset				= $('#footer').offset().top,
		// 			windowHeight 	= $(window).height();

		// 	return (scrollTop + windowHeight) > offset ? true : false;
		// };



		filters.on('click', function(e) {
			e.preventDefault();

			if(activate($(this))) {
				menuOpen = false;
				showFilters();
				filter($(this).data('category'));
				hideHeadings();
				setScrolltop();
			} else {
				filter(false);
				showHeadings();
				setScrolltop();
			}
		});

		document.addEventListener('lazybeforeunveil', function(e) {
			var item = $(e.target);

			item.parent().parent().addClass('is-loaded');
		});

		$(window).ready(function() {
			if(filterContainer.attr('data-filter')) {
				var filterData = filterContainer.data('filter');
				filterData = parseInt(filterData);

				console.log(filterData);

				filter(filterData);
				hideHeadings();
			}
		});

		$(window).on('load scroll resize', function() {
			if (inViewport()) {
				showFilters();
			} else if (!inViewport()) {
				hideFilters();
			}
		});

		$(window).on('breakpoint-change', function(e, breakpoint) {
			bp = breakpoint;

	    if(breakpoint !== 'bp-medium' && breakpoint !== 'bp-small') {
	    	mobile = false;
	    	menuOpen = false;
	    } else {
	    	mobile = true;
	    }
	 	});

	 	toggleMenu.on('click', function() {
	 		if(!menuOpen) {
	 			menuOpen = true;
	 			showFilters();
	 		} else {
	 			menuOpen = false;
	 			showFilters();
	 		}
	 	});
	}
})();